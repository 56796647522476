<template>
  <div>
    <titles :title="$route.query.type == 'new' ? $t('text.t434') : $route.query.type == 'hot' ? $t('text.t438') : ''" />
    <van-list v-model="loading" :finished="finished" :finished-text="$t('text.t39')" :offset="10" @load="getlist">
      <div class="nftlist start-center" v-if="list.length">
        <div class="items-out" v-for="(item, index) in list" :key="index">
          <div class="items" @click="goAhead(`/newdetail?orderno=${item.orderno}&chainType=${item.chain}`)">
            <img :src="item.info.image" class="i1" />
            <div class="b1 between-center">
              <div class="t1 ellipsis">{{ item.title }}</div>
            </div>
            <div class="b2 start-center">
              <img v-if="item.identification == 1" src="@/assets/img/img85.png" class="left-imgs-pos" />
              <img v-else-if="item.identification == 2" src="@/assets/img/img86.png" class="left-imgs-pos" />
              <div>#{{ item.tokenid }}</div>
            </div>
            <div class="b3 start-center">
              <img :src="chain_list.find(ele => ele.chain_type == item.chain).chain_logo" class="b3i1" />
              <div class="b3t1">{{ item.price }}</div>
            </div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import titles from "@/components/titles/index.vue";
import { mapState } from "vuex";
export default {
  components: {
    titles,
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 1,
      limit: 20,
    };
  },
  computed: {
    ...mapState(["language", "chain_list"]),
  },
  activated(){
    if(!this.$route.meta.useKeep){
      this.page = 1
      this.loading = false
      this.finished = false
      this.list = []
    }
  },
  deactivated(){},
  methods: {
    getlist() {
      let arg = {
        page: this.page,
        limit: this.limit
      };
      const url = this.$route.query.type == 'new' ? "/core/newest" : "/core/hot"
      this.$http.get(url, arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished = true;
        this.page++;
        this.loading = false;
        this.list = [...this.list, ...data];
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    from.meta.useKeep = true;
    next();
  }
};
</script>

<style lang="less">
.nftlist {
  flex-wrap: wrap;
  padding-top: 10px;

  .items-out {
    padding: 0 18px 25px;

    .items {
      width: 150px;

      .i1 {
        width: 100%;
        height: 150px;
        border-radius: 3px;
        margin-bottom: 5px;
      }

      .b1 {
        padding: 0 16px 9px 0;

        .t1 {
          flex: 1;
          font-size: 12px;
          font-weight: 500;
          color: #909090;
        }

        .b1i1 {
          width: 10px;
          height: 10px;
          margin-left: 10px;
        }
      }

      .b2 {
        font-size: 16px;
        font-weight: bold;
        color: #323232;
        margin-bottom: 9px;

        .left-imgs-pos {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-right: 5px;
        }
      }

      .b3 {
        .b3i1 {
          width: 15px;
          height: 15px;
          margin-right: 4px;
        }

        .b3t1 {
          font-size: 12px;
          font-weight: 500;
          color: #323232;
        }
      }
    }
  }
}
</style>